import { Box, Button, CircularProgress, InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Pagination } from '@mui/material'
import React, { useState, useContext, useEffect } from 'react'
import Main from '../components/Main';
import MainHeader from '../components/MainHeader'
import { AuthContext } from '../utils/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { deleteStudentRecord, fetchStudentRecords } from '../services/StudentRecordService';
import { fetchUser } from '../services/UserService';
import { toast } from 'react-toastify';
import AlertDialog from '../components/AlertDialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Global from '../utils/Global';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';

import '../components/globalState';

import moment from 'moment';

function StudentRecords() {

  const { auth } = useContext(AuthContext);

  const [open, setOpen] = useState(localStorage.getItem('drawer-open') == 'true');

  const navigate = useNavigate();

  const [students, setStudents] = useState([]);
  const [creatorName, setCreatorNames] = useState({});
  const [editorName, setEditorNames] = useState({});

  const [studentToDelete, setStudentToDelete] = useState(null);

  const [isTableLoading, setTableLoading] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');



  useEffect(() => {
    handleFetchStudentRecords();
  }, []);


  async function fetchCreatorNames(studentRecords) {
    const names = {};

    for (const student of studentRecords) {
      if (student.creator_id) {
        try {
          const userResponse = await fetchUser({ token: auth.token, userId: student.creator_id });

          if (userResponse && userResponse.data && userResponse.data.user) {
            names[student.creator_id] = `${userResponse.data.user.first_name} ${userResponse.data.user.last_name}`;
          }
        } catch {

        }
      }
    }

    setCreatorNames(names);
  }


  async function fetchEditorNames(studentRecords) {
    const names = {};

    for (const student of studentRecords) {
      if (student.editor_id) {
        try {
          const userResponse = await fetchUser({ token: auth.token, userId: student.editor_id });

          if (userResponse && userResponse.data && userResponse.data.user) {
            names[student.editor_id] = `${userResponse.data.user.first_name} ${userResponse.data.user.last_name}`;
          }
        } catch {

        }
      }
    }

    setEditorNames(names);
  }

  async function handleFetchStudentRecords() {
    setTableLoading(true);

    try {

      let response = await fetchStudentRecords({
        token: auth.token,
        firstName: firstName,
        lastName: '',
        dateOfBirth: dateOfBirth == null ? '' : dateOfBirth.format('YYYY-MM-DD'),
      });

      let studentRecords = response.data.students;


      if (studentRecords.length === 0) {
        response = await fetchStudentRecords({
          token: auth.token,
          firstName: '',
          lastName: firstName,
          dateOfBirth: dateOfBirth == null ? '' : dateOfBirth.format('YYYY-MM-DD'),
        });

        studentRecords = response.data.students;
      }


      setStudents(studentRecords);
      await fetchCreatorNames(studentRecords);
      await fetchEditorNames(studentRecords);

    } catch (error) {
      console.error('Error fetching student records:', error);

    } finally {
      setTableLoading(false);
    }
  }

  async function handleFetchStudentRecordsAll() {
    setTableLoading(true);

    const response = await fetchStudentRecords({ token: auth.token, firstName: '', lastName: '', dateOfBirth: '' });

    const studentRecords = response.data.students;
    setStudents(studentRecords);
    await fetchCreatorNames(studentRecords);
    await fetchEditorNames(studentRecords)

    setTableLoading(false);
  }

  async function handleDeleteStudentRecord() {
    const response = await deleteStudentRecord({ token: auth.token, studentId: studentToDelete.id }).catch(err => {
      if (err.response.status == 422) {
        toast.error(err.response.data.message, {
          autoClose: 1000,
          hideProgressBar: true
        });
      }
    });

    if (response) {
      toast.success('Student Record Deleted', {
        autoClose: 1000,
        hideProgressBar: true
      });

      handleFetchStudentRecords();
    }

  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleFetchStudentRecords();
    }
  };

  const detectAndSetNames = (input) => {

    const trimmedInput = input.trim();


    const parts = trimmedInput.split(' ');

    if (parts.length === 1) {

      setFirstName(trimmedInput);
      setLastName('');
    } else if (parts.length >= 2) {

      setFirstName(parts[0]);
      setLastName(parts.slice(1).join(' '));
    }
  };



  const currentPageStudents = students.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  const totalPages = Math.ceil(students.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };


  return (
    <Box sx={{ display: 'flex', background: '#ebf2fc', height: 'auto' }}>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 3 }} >


        <Paper
          elevation={0}
          sx={{
            p: 3,
            backgroundColor: '#ffffff',
            borderRadius: '20px',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: -1, mb: 1 }}>
            <Typography variant='h4' sx={{ mt: 2.5, fontWeight: 700, color: '#09213b' }}> Student Records </Typography>


            <TextField
              label="Search..."
              variant="filled"
              sx={{
                mt: 1.5,
                ml: -4,
                width: '300px',
                color: '#ffffff',
                '& .MuiFilledInput-root': {
                  backgroundColor: '#e9eef6',
                  borderRadius: '20px',
                },
                '& .MuiInputLabel-root': {
                  color: '#ffffff',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#ffffff',
                },
                '& .MuiFilledInput-root:before, & .MuiFilledInput-root:after': {
                  borderBottom: 'none',
                },
                '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
                  borderBottom: 'none',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: '#a6a6a6' }} />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              onChange={(e) => detectAndSetNames(e.target.value)}
              onKeyDown={handleKeyDown}
            />



            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  ml: -0.2,
                  padding: 1.7,
                  backgroundColor: '#113f70',
                  color: 'white',
                  boxShadow: '',
                  borderRadius: '10px',
                  textTransform: 'none',
                  '&:hover': {
                    borderColor: '#3e5bb7',
                    backgroundColor: '#5271ff',
                    color: 'white',
                  },
                }}
                onClick={() => {
                  navigate('/student_records/add');
                }}
              >
                Add Student Record
              </Button>



              {/* 
              <Button
                sx={{
                  mr: 1,
                  ml: 5,
                  mt: 1,
                  padding: 1,
                  width: '110px',
                  color: 'white',
                  borderWidth: 2,
                  boxShadow: 'none',
                  backgroundColor: '#113f70',
                  borderRadius: '10px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#5271ff',
                    color: 'white'
                  }
                }}
                variant="filled"
                onClick={() => {
                  handleFetchStudentRecords();
                }}
              >
                Search
              </Button>


              /* <Button
                sx={{
                  mr: 1,
                  ml: 3,
                  mt: 1,
                  padding: 1.2,
                  width: '150px',
                  backgroundColor: '#545454',
                  color: '#a6a6a6',
                  boxShadow: 'none',
                  borderRadius: '10px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#545454',
                    color: 'white'
                  }
                }}
                onClick={() => {
                  setFirstName('');
                  setLastName('');
                  setDateOfBirth(null);
                  handleFetchStudentRecordsAll();
                }}
              >
                Clear Search
              </Button> 
              */}
            </Box>
          </Box>



        </Paper >


        <Paper sx={{ px: 3, py: 2, mt: 2, borderRadius: '20px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="student records table" size='small'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: '300', fontSize: '13px' }}> First Name </TableCell>
                <TableCell sx={{ fontWeight: '300', fontSize: '13px' }}> Last Name </TableCell>
                <TableCell sx={{ fontWeight: '300', fontSize: '13px' }}> Date of Birth </TableCell>
                <TableCell sx={{ fontWeight: '300', fontSize: '13px' }}> Date Created </TableCell>
                <TableCell sx={{ fontWeight: '300', fontSize: '13px' }}> Last Edited </TableCell>
                <TableCell align="left" sx={{ fontWeight: '300', fontSize: '13px', paddingLeft: 6.5}}> Actions </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
                isTableLoading ?
                  <TableRow>
                    <TableCell colSpan={6} align='center'>
                      <CircularProgress size={25} />
                    </TableCell>
                  </TableRow>
                  :
                  currentPageStudents.map((student, index) => (
                    <TableRow key={student.id}
                      sx={{
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: '#f2f6fc',
                        },
                      }}>
                      <TableCell>{student.first_name}</TableCell>
                      <TableCell>{student.last_name}</TableCell>
                      <TableCell>{student.date_of_birth}</TableCell>

                      <TableCell>
                        <Typography variant="caption" sx={{ display: 'flex', mr: 1 }}>
                          {student.date_created ? moment(student.date_created).format('MMMM D, YYYY') : ''}
                        </Typography>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {student.date_created ? moment(student.date_created).format('h:mm A') : ''}
                        </Typography>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {creatorName[student.creator_id] || ''}
                        </Typography>

                      </TableCell>

                      <TableCell>
                        <Typography variant="caption" sx={{ display: 'flex', mr: 1 }}>
                          {student.date_edited ? moment(student.date_edited).format('MMMM D, YYYY') : ''}
                        </Typography>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {student.date_edited ? moment(student.date_edited).format('h:mm A') : ''}
                        </Typography>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {editorName[student.editor_id] || ''}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
                          <Tooltip title='Edit Student Record'>
                            <IconButton                       // EDIT BUTTON
                              sx={{
                                color: '#113f70',
                                '&:hover': {
                                  color: '#5271ff',
                                },
                              }}
                              onClick={() => {
                                navigate('/student_records/edit/' + student.id);
                              }}
                            >
                              <EditIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title='Delete'>
                            <IconButton              // DELETE BUTTON
                              sx={{
                                ml: 1,
                                color: '#113f70',
                                '&:hover': {
                                  color: '#5271ff',
                                },
                              }} onClick={() => {
                                setShowDeleteDialog(true);
                                setStudentToDelete(student);
                              }}>
                              <DeleteIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>


                          <Link to={Global.baseURL + '/api/student_records/download_pdf?student_id=' + student.id}>
                            <Tooltip title='Download PDF'>
                              <IconButton             // DOWNLOAD PDF BUTTON
                                sx={{
                                  ml: 1,
                                  color: '#113f70',
                                  '&:hover': {
                                    color: '#5271ff'
                                  }
                                }}>
                                <DownloadIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>
                          </Link>

                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
              }


            </TableBody>
          </Table>

          <Box display="flex" justifyContent="right" sx={{ mt: 2 }}>
            <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" size='small' />
          </Box>


        </Paper>



        <AlertDialog
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
          title="Delete Student Record"
          content={
            <div>
              <p>
                Do you want to delete "<strong>{studentToDelete?.first_name} {studentToDelete?.last_name}</strong>"?
              </p>
              <p style={{ color: 'red' }}>
                Please type <strong>"Delete Student Record"</strong> to confirm.
              </p>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Type 'Delete Student Record' here"
                value={deleteConfirmationText}
                onChange={(e) => setDeleteConfirmationText(e.target.value)}
                error={!!errorMessage}
                helperText={errorMessage}
              />
            </div>
          }
          positiveButtonLabel="Delete"
          negativeButtonLabel="Cancel"
          callback={() => {
            if (!deleteConfirmationText.trim()) {

              setErrorMessage('This field is required');
            } else if (deleteConfirmationText !== 'Delete Student Record') {

              setErrorMessage('This field is incorrect');
            } else {

              handleDeleteStudentRecord();
              setShowDeleteDialog(false);
              setDeleteConfirmationText('');
              setErrorMessage('');
            }
          }}
        />

        <Box sx={{ height: 50 }}></Box>

      </Main >
    </Box >
  )
}

export default StudentRecords;
